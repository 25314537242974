import React, { useState, useEffect } from 'react';
import * as BS from 'react-bootstrap'
import axios from "axios"

const BASE_URL = window.location.origin.toString()

const sshPlaceholder = "-----BEGIN OPENSSH PRIVATE KEY----- \n ...\n -----END OPENSSH PRIVATE KEY----- "


export default function KeyManagerComponent({ auth, setNav }) {
  const [hasSshKey, setHasSshKey] = useState(undefined)
  const [sshKey, setSshKey] = useState("")
  const [error, setError] = useState("")

  const onChange = function (event) {
    const { name, value } = event.target
    switch (name) {
      case "hasSshKey":
        setHasSshKey(value)
        break
      case "sshKey":
        setSshKey(value)
        break
      case "error":
        setError(value)
        break
      default:
        return
    }
  }

  useEffect(() => {
    axios.post(`${BASE_URL}/sshkey/query`, { auth })
      .then(res => {
        const result = res.data
        setHasSshKey(result.hasKey)
      })
  }, [auth])

  const submit = function (event) {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }

    const formData = {
      auth,
      sshKey
    }

    axios.post(`${BASE_URL}/sshkey/set`, formData)
      .then(res => {
        setError("")
        setHasSshKey(true)
      })
      .catch(err => {
        setError(err.message)
      })

    event.preventDefault()

  }


  return <BS.Container>
    <BS.Row>
      <BS.Col>
        <h1> Gérer ma clef SSH </h1>
        {error ? <BS.Alert variant="danger">{error}</BS.Alert> : ""}
        {hasSshKey !== undefined ? hasSshKey ?
          <BS.Alert variant="light">Vous avez déja une clef SSH. Vous pouvez la modifier.</BS.Alert> :
          <BS.Alert variant="warning">Vous n'avez pas de clef SSH.</BS.Alert>
          : ""}

        {hasSshKey !== undefined ?
          <>
            <BS.Form onSubmit={submit} className="spaced">
              <BS.Col>
                <BS.Row>
                  <div className="formUnit">
                    <BS.Form.Label>Clef Privée SSH</BS.Form.Label>
                    <BS.Form.Control type="text" as="textarea" rows={3} name="sshKey" value={sshKey} onChange={onChange} placeholder={sshPlaceholder} />
                    <BS.Form.Text className="text-muted">
                      Utilisez si possible une clef spécialement prévue à cet effet et ne la réutilisez pas. N'oubliez pas de lier la clef publique correspondante à Gitlab.
                    </BS.Form.Text>
                  </div>
                </BS.Row>
              </BS.Col>
              <BS.Button type="submit">Valider</BS.Button>
            </BS.Form >
          </> : "Chargement..."}
      </BS.Col>
    </BS.Row>
  </BS.Container >

}