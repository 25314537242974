import React from 'react';
import ReactMarkdown from 'react-markdown'
import * as BS from 'react-bootstrap'

const markdown = `
# Documentation

## Base

Ce service vous donne accès à un environnement de développement containerisé.
Dans celui-ci, vous disposez d'un accès root et de tous les outils nécéssaires
pour développer un projet nodeJS (ou Fortran, on commet tous des erreurs...).


## Configuration et sécurité

- Générer une paire de clef SSH sur votre machine locale.
- Ajouter la clef **publique** au **Gitlab** de la société.
- Ajouter la clef **privée** à ce service.
- Supprimez la paire de clef de votre machine locale. Ne réutilisez pas cette paire de clef à d'autre fins, et surtout n'ajoutez jamais la clef publique à une autre machine.
- Vous êtes prêt à utiliser le service.


Un mot de passe temporaire est généré à la création pour accéder aux 
éventuels services annexes.
Dans le contexte de l'environnement, vous pouvez retrouver ce mot de passe
via la commande ${"`cat ~/.password`"} 

## Contact et support

- support.skales.fr, project "SI Skales"
- lucas.vincent@skales.fr (en cas d'urgence)

## Services annexes

### Base de donnée relationelle (SGBD)

L'environnement dispose d'une base MariaDB. Vous disposez de l'accès suivant :
${"```"}
host: database
user: root
password: *votre mot de passe temporaire généré*
port: 3306
${"```"}

### Redis

L'environnement dispose de deux serveurs redis : accessible via les hôtes
${"`redis`"} et ${"`queue`"} sur les ports par défaut.
Ils sont configuré essentiellement pour Simtopy mais peuvent être
exploités pour d'autre fins.

### Adminer

Vous pouvez accéder à adminer via le chemin en ajoutant 
${"`/adminer`"} dans l'URL.

## Utilitaires

### Fermer la session de développement

Vous pouvez arrêter l'environnement de développement à l'aide de la 
commande ${"`stopEnv.sh`"} dans le terminal.

### SSH port forwarding

Cette fonctionnalité vous permet d'exposer un port vers une autre machine du SI SKALES,
sous réserve de disposer de permissions suffisantes.

Utilisation : ${"`forwardPort.sh PORT HOST USER`"}, ex : ${"`forwardPort.sh 3000 lovelace.skales.lan lucas`"}
Ensuite, vous aurez accès à ce port sur votre machine locale, au même numéro de port jusqu'à ce que
la connexion soit fermée.

### Plage de ports

Les port 3000 à 3010 sont mis à disposition pour exposer des serveurs HTTP.
Ils sont accessible en ajoutant le numéro de port au chemin de l'URL du
container (ex /3000).

## Autoconfiguration des projets

Vous pouvez, dans votre projet, écrire un script sous le chemin ${"`.skales/autoconf.sh`"}
et lui assigner les permissions d'écriture : ${"`chmod +x .skales/autoconf.sh`"} depuis
la racine du projet.

S'il existe, ce script est utilisé pour installer votre projet pour l'environnement
de développement. Vous avez accès à des variables d'environnement dont :

${"```"}
HTTP_USER: votre nom d'utilisateur
HTTP_PASSWORD: votre mot de passe temporaire 
${"```"}

`

export default function DocComponent() {
  return <BS.Container>
    <BS.Row>
      <BS.Col>
        <ReactMarkdown children={markdown} />
      </BS.Col>
    </BS.Row>
  </BS.Container>
}