import React, { useState, useEffect } from 'react';
import * as BS from 'react-bootstrap'
import axios from "axios"

const BASE_URL = window.location.origin.toString()

function loadList(setContainers, auth) {
   axios.post(`${BASE_URL}/user/containers/list`, { auth })
      .then(res => {
        const result = res.data
        setContainers(result)
      })
}

function containerAction(actionType, container, setContainers, auth) {
  return () => {
    switch (actionType) {
      case "join": {
        window.location.replace(`${BASE_URL}/instance/${container.uid}/${container.auth}/`)
        break
      }
      case "delete": {
        axios.post(`${BASE_URL}/devenv/destroy`, { uid: container.uid, auth: container.auth, cause: "manual action in frontend" })
        break
      }
      default: {
        console.error("action unsupported")
      }
    }
    loadList(setContainers, auth)
  }
}

function containerRow(cont, setContainers, auth) {
  const ttl = cont.ttl - (Date.now() - cont.createdAt)
  const ttlSeconds = Math.floor(ttl / 1000)
  const ttlMinutes = Math.floor(ttlSeconds / 60)
  const ttlHours = Math.floor(ttlMinutes / 60)
  const ttlDays = Math.floor(ttlHours / 24)
  const ttlDisplay = ttl < 0 ? "expiré !" : `${ttlDays}j ${ttlHours}h ${ttlMinutes}m`
  return <tr>
    <td>{ cont.uid }</td>
    <td> { cont.image }</td>
    <td> { ttlDisplay } </td>
    <td>
      <BS.Button variant="primary" size="sm" onClick={containerAction("join", cont, setContainers, auth)}>Rejoindre</BS.Button>
      <BS.Button variant="danger" size="sm" onClick={containerAction("delete", cont, setContainers, auth)}>Supprimer</BS.Button>
    </td>
  </tr>
}

export default function ContainerManagementComponent({ auth }) {

  const [containers, setContainers] = useState([])

  useEffect(() => {
    axios.post(`${BASE_URL}/user/containers/list`, { auth })
      .then(res => {
        const result = res.data
        setContainers(result)
      })
  }, [auth])
  

  return <BS.Container>
    <BS.Row>
      <BS.Col>
        <h1>Gestion des containers</h1>
        <BS.Table>
          <thead>
            <tr>
              <th>UID</th>
              <th>Image</th>
              <th>Durée de vie restantes</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            { containers.map(c => (containerRow(c, setContainers, auth))) }
          </tbody>
        </BS.Table>
      </BS.Col>
    </BS.Row>
  </BS.Container>
  
}




