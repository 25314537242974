import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import CreateContainerComponent from './components/createContainer.js'
import AuthComponent from "./components/auth.js"
import KeyManagerComponent from "./components/keyManager.js"
import DocComponent from "./components/doc.js"
import ContainerManagementComponent from './components/containers.js';
import { Nav } from "react-bootstrap"
import axios from "axios"

const BASE_URL = window.location.origin.toString()

const buildNav = (setNav) => <>
  <Nav defaultActiveKey="/" as="ul" onSelect={(selectedKey) => setNav(selectedKey)}>
    <Nav.Item as="li">
      <Nav.Link eventKey="/">Instanciation</Nav.Link>
    </Nav.Item>
    <Nav.Item as="li">
      <Nav.Link eventKey="/management">Gestion des containers</Nav.Link>
    </Nav.Item>
    <Nav.Item as="li">
      <Nav.Link eventKey="/keyManagement">Gérer ma clef SSH</Nav.Link>
    </Nav.Item>
    <Nav.Item as="li">
      <Nav.Link eventKey="/doc">Documentation</Nav.Link>
    </Nav.Item>
    <Nav.Item as="li">
      <Nav.Link eventKey="/logout">Déconnexion</Nav.Link>
    </Nav.Item>
  </Nav>
</>

function BaseComponent() {
  const [authToken, setAuthToken] = useState(localStorage['authToken'])
  const [nav, setNav] = useState("/")

  useEffect(() => {
    if (nav === "/logout") {
      delete localStorage['authToken']
      setAuthToken(undefined)
      setNav("/")
    }
  }, [nav])

  useEffect(() => {
    if (authToken) {
      axios.post(`${BASE_URL}/user/checkauth`, {auth: authToken}).catch(err => {
        delete localStorage['authToken']
        window.location.reload()
      })
    }
  }, [authToken])


  if (authToken) {
    if (nav === "/management") {
      return <>
        {buildNav(setNav)}
        <ContainerManagementComponent auth={authToken} />
      </>
    }
    if (nav === "/keyManagement") {
      return <>
        {buildNav(setNav)}
        <KeyManagerComponent auth={authToken} />
      </>
    }
    else if (nav === "/doc") {
      return <>
        {buildNav(setNav)}
        <DocComponent />
      </>
    }
    else {
      return <>
        {buildNav(setNav)}
        <CreateContainerComponent auth={authToken} />
      </>

    }
  }
  else return <AuthComponent setAuthToken={setAuthToken} />
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BaseComponent />
  </React.StrictMode>
)